import { RefObject } from "react";

// helper function
export function isEmail(email: any) {
  const re: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function setError(input: RefObject<HTMLDivElement>, msg: string) {
  input.current?.classList.add("error");
  const small: any = input.current?.querySelector("small");

  // add error class and message
  small.innerText = msg;
  input.current!.className = "form_group error";
}

export function setSuccess(input: RefObject<HTMLDivElement>) {
  input.current!.className = "form_group success";
}
