import "./Footer.css";
import { useState, useEffect } from "react";

// firebase firestore
import { fetchPageView, incrementPageView } from "../../db/firebase";

// svg
import logo from "../../assets/svg/logo/SHIOM_LOGO_type_orange_.svg";

const Footer = () => {
  const [visitors, setVisitors] = useState<number | string>("...");

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      incrementPageView().then(() => {
        fetchPageView()
          .then((res: any) => setVisitors(res?.count))
          .catch((e) => console.log(e));
      });
    }
  }, []);

  return (
    <footer>
      <div className="container text-center">
        <div className="footer_content">
          <div className="nav_brand">
            <img src={logo} alt="shiom logo" />
          </div>

          <div>
            <small className="visitors">Total visitors: {visitors}</small>
            <p className="mt-1">Copyright © {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
