import "./InvestorRelationsPage.css";

// components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const InvestorRelationsPage = () => {
  return (
    <>
      <Navbar />
      <main className="investor_relations">
        <div className=" text-center mt-1">
          <h1>Investor Relations</h1>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default InvestorRelationsPage;
