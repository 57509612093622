import {
  collection,
  addDoc,
  where,
  query,
  onSnapshot,
  updateDoc,
  doc,
  increment,
  serverTimestamp,
  QuerySnapshot,
  DocumentData,
  arrayUnion,
  getDocs,
} from "firebase/firestore";

import { db, storage } from "./firebase-config";
import {
  StorageReference,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Dispatch, SetStateAction } from "react";

/////////////////////////////
// create a doc
export const addADocument = (collectionName: string, data: any) => {
  return new Promise(async (resolve, reject) => {
    // collection ref
    const colRef = collection(db, collectionName);

    try {
      const res = await addDoc(colRef, {
        ...data,
        createdAt: serverTimestamp(),
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchByCountry = () => {
  // collection ref
  const colRef = collection(db, "visitors");

  const q = query(
    colRef,
    where("countryName", "not-in", [
      "United States of America (the)",
      "United States of America",
    ])
    //where("city", "==", "Kolkata")
  );

  onSnapshot(q, (docs) => {
    console.log(docs.size);
    docs.forEach((doc) => {
      console.log(doc.data());
    });
  });
};

//////////////////
// visitor counter
export const incrementPageView = async () => {
  const docRef = doc(db, "pageview", "--stats--");

  await updateDoc(docRef, { count: increment(1) });
};

export const fetchPageView = () => {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "pageview", "--stats--");

    try {
      onSnapshot(docRef, (doc) => {
        resolve({ ...doc.data(), id: doc.id });
      });
    } catch (error) {
      reject(error);
    }
  });
};

/// check user email exist or not
export const emailExist = (
  email: string
): Promise<QuerySnapshot<DocumentData>> => {
  return new Promise((resolve) => {
    // collection ref
    const colRef = collection(db, "interestedUsers");

    const q = query(colRef, where("email", "==", email));

    onSnapshot(q, (docs) => resolve(docs));
  });
};

// add image to firebase storage and
//  add a doc with img url
export const addImageToFirebase = async (
  file: File,
  description: string,
  // setPercent: Dispatch<SetStateAction<number>>,
  uid: string
) => {
  try {
    // image upload to firebase storage

    const storageRef = ref(storage, `gallery-images/${file.name}`);

    const uploadTask = await uploadBytesResumable(storageRef, file);

    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    // const progress = Math.round(
    //   (uploadTask.bytesTransferred / uploadTask.totalBytes) * 100
    // );

    // switch (uploadTask.state) {
    //   case "paused":
    //     console.log("Upload is paused");
    //     break;
    //   case "running":
    //     console.log(progress);
    //     setPercent(progress);
    //     break;
    //   case "success":
    //     setPercent(0);
    //     break;
    // }

    const URL = await getDownloadURL(uploadTask.ref);

    // collection ref
    const colRef = collection(db, "gallery");
    const imgInfo = {
      url: URL,
      name: file.name,
      type: file.type,
      createdBy: uid,
      createdAt: serverTimestamp(),
      description,
    };
    const { id } = await addDoc(colRef, imgInfo);

    return {
      id,
      message: "Image successfully uploaded",
    };
  } catch (e) {
    console.log(e);

    return {
      error: "Something went wrong",
    };
  }
};

// get images
export const getGalleryImages = async () => {
  const colRef = collection(db, "gallery");

  const { docs } = await getDocs(colRef);
  const data = docs.map((d) => ({ ...d.data(), id: d.id }));

  return data;
};
