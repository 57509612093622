import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// components
import Footer from "./components/Footer/Footer";

// pages
import HomePage from "./pages/HomePage/HomePage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import InvestorRelationsPage from "./pages/InvestorRelationsPage/InvestorRelationsPage";
// import GalleryPage from "./pages/GalleryPage/GalleryPage";
// import AdminDashboard from "./pages/Admin/AdminDashboard/AdminDashboard";
// import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/investor-relations"
            element={<InvestorRelationsPage />}
          />
          {/* <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-login" element={<AdminLogin />} /> */}

          {/* error page */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />

      <Toaster />
    </>
  );
};

export default App;
