import { ChangeEvent, FormEvent, useState, useRef } from "react";
import "./EmailModal.css";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

// utils
import { isEmail, setSuccess, setError } from "../../utils/validation";

const Modal = ({ setIsOpen }: any) => {
  const initialState = { name: "", email: "", message: "" };
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const nameInputRef = useRef<HTMLDivElement | null>(null);
  const emailInputRef = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLDivElement | null>(null);

  const { name, email, message } = values;

  // change handler
  const changeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;

    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputHandler = (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const form_group = e.currentTarget.parentElement;

    form_group?.classList.remove("error");
  };

  // submit handler
  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name, email, message } = values;

    let nameValidated = false;
    let emailValidated = false;
    let messageValidated = false;

    // input validation
    if (name === "") {
      setError(nameInputRef, "Name can not be blank");
    } else if (name!.length < 3) {
      setError(nameInputRef, "Please enter at least 3 characters");
    } else {
      setSuccess(nameInputRef);
      nameValidated = true;
    }

    if (email === "") {
      // emailInputRef.current?.classList.add("error");
      setError(emailInputRef, "Email can not be blank");
    } else if (!isEmail(email)) {
      setError(emailInputRef, "Email is invalid");
    } else {
      setSuccess(emailInputRef);
      emailValidated = true;
    }

    if (message === "") {
      setError(messageInputRef, "Message can not be blank");
    } else if (message!.length < 10) {
      setError(messageInputRef, "Please enter at least 10 characters");
    } else {
      setSuccess(messageInputRef);
      messageValidated = true;
    }

    if (nameValidated && emailValidated && messageValidated) {
      setLoading(true);
      setErrorMsg("");

      try {
        const res = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_TEMPLATE_ID as string,
          values,
          {
            publicKey: process.env.REACT_APP_PUBLIC_KEY as string,
          }
        );
        // console.log("SUCCESS!", res);

        if (res.status === 200 && res.text === "OK") {
          // setLoading(false);
          setValues(initialState);

          document.querySelectorAll(".form_group").forEach((el) => {
            el.classList.remove("success");
          });

          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 8000);
        }
      } catch (error) {
        // console.log(error);

        if (error instanceof EmailJSResponseStatus) {
          // console.log("EMAILJS FAILED...", error.text);
          setErrorMsg("Email failed to send, please resend.");
          return;
        }

        setErrorMsg("Something went wrong please try again");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="overlay" onClick={() => setIsOpen(false)} />

      <div className="d-flex">
        <div className="modal_container">
          <form onSubmit={submitHandler} noValidate>
            <div ref={nameInputRef} className="form_group">
              <label className="form_label" htmlFor="name">
                Name
              </label>
              <input
                className="form_input"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={changeHandler}
                onInput={onInputHandler}
              />

              <i className="fas fa-check-circle"></i>
              <i className="fas fa-exclamation-circle"></i>
              <small>Some error message</small>
            </div>

            <div ref={emailInputRef} className="form_group">
              <label className="form_label" htmlFor="email">
                Email
              </label>
              <input
                className="form_input"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={changeHandler}
                onInput={onInputHandler}
              />

              <i className="fas fa-check-circle"></i>
              <i className="fas fa-exclamation-circle"></i>
              <small>Some error message</small>
            </div>

            <div ref={messageInputRef} className="form_group">
              <label className="form_label" htmlFor="message">
                Message
              </label>
              <textarea
                className="form_input"
                name="message"
                id="message"
                cols={30}
                rows={5}
                value={message}
                onChange={changeHandler}
                onInput={onInputHandler}
              ></textarea>

              <i className="fas fa-check-circle"></i>
              <i className="fas fa-exclamation-circle"></i>
              <small>Some error message</small>
            </div>

            <div className="text-center">
              {successMsg && (
                <small className="s-msg">
                  <i className="fas fa-check-circle"></i> Your message has been
                  sent successfully. Thank you for getting in touch!
                </small>
              )}

              {errorMsg && (
                <small className="error-msg">
                  <i className="fas fa-exclamation-circle"></i> {errorMsg}
                </small>
              )}
            </div>

            <div className="form_btns mt-1">
              <button
                className="btn btn-danger"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "sending.." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Modal;
