import "./ErrorPage.css";

// components
import Navbar from "../../components/Navbar/Navbar";

// img
import errImg from "../../assets/img/404_error.png";

const ErrorPage = () => {
  return (
    <>
      <Navbar />

      <div className="container error_page text-center">
        <div className="error_page_left">
          <img src={errImg} alt="404 error page" />
        </div>

        <div className="error_page_right">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            Go to <a href="/">home</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
