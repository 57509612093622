import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { TbBabyCarriage } from "react-icons/tb";

import { auth } from "../db/firebase-config";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from "firebase/auth";

interface IAuthContext {
  loading: boolean;
  currentUser: any;
  signUp: any;
  logIn: any;
  logOut: any;
}

const defaultValue = {
  loading: true,
  currentUser: {},
  signUp: (email: "", password: "") => {},
  logIn: (email: "", password: "") => {},
  logOut: () => {},
};

const AuthContext = createContext<IAuthContext>(defaultValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      // console.log("user cred", user);

      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsub();
  }, []);

  const signUp = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password);

  const logIn = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const logOut = () => signOut(auth);

  const value = {
    loading,
    currentUser,
    signUp,
    logIn,
    logOut,
  };

  // if (loading)
  //   return (
  //     <h1 className="text-center mt-2">
  //       <TbBabyCarriage size={"3rem"} />
  //     </h1>
  //   );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
