import "./Navbar.css";
import { Link } from "react-router-dom";

// svg
import logo from "../../assets/svg/logo/SHIOM_LOGO_type_orange_.svg";

const Navbar = () => {
  return (
    <nav>
      <div className="container navbar">
        <Link to={"/"} className="nav_brand">
          <img src={logo} alt="shiom logo" />
        </Link>

        <div className="nav_links">
          <Link to={"/"}>Home</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
