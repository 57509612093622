import audioIcon from "./audio_icon.svg";
import aviationIcon from "./aviationIcon.svg";
import cardioIcon from "./cardio-icon.svg";
import ctScanIcon from "./ct-scan-icon.svg";
import breathingIcon from "./breathing_connector.svg";
import monalisaIcon from "./monalisa.svg";
import neurologicalIcon from "./Neurological-icon.svg";
import oilICon from "./oil-industry_icon.svg";
import photographyIcon from "./photography.svg";
import spaceIcon from "./space.svg";
import ultraSoundIcon from "./ultrasound.svg";
import xRayIcon from "./xray-icon.svg";

const clinical = [
  { svg: ctScanIcon, name: "Radiology", url: "/" },
  { svg: ultraSoundIcon, name: "Ultra Baby", url: "https://ultrababy.io/" },
  { svg: cardioIcon, name: "Cardio", url: "/" },
  { svg: xRayIcon, name: "X-ray", url: "/" },
  { svg: neurologicalIcon, name: "AI", url: "/" },
  { svg: breathingIcon, name: "Breath connect", url: "/" },
];
const generic = [
  { svg: audioIcon, name: "Audio & video", url: "/" },
  { svg: aviationIcon, name: "Aviation", url: "/" },
  { svg: monalisaIcon, name: "Art", url: "/" },
  { svg: oilICon, name: "Oil industry", url: "/" },
  { svg: photographyIcon, name: "Photography", url: "/" },
  { svg: spaceIcon, name: "Space", url: "/" },
];

export { clinical, generic };
