import "./CookieModal.css";

// cookies svg
import cookiesSvg from "../../assets/svg/cookies.svg";

const CookieModal = ({
  setAcceptCookies,
  setOpenCookie,
  setCookieDenied,
}: any) => {
  return (
    <div className="container cookieModal">
      <div>
        <div className="cookie-head">
          <img src={cookiesSvg} alt="cookies svg" />
          <p className="cookie-text">
            We use cookies and similar technologies to{" "}
            <b>recognize your repeat visits and preferences</b>, as well as to
            <b> measure the effectiveness of campaigns and analyze traffic</b>.
            Also this website uses cookies to ensure you get the best user
            experience.
          </p>
        </div>

        <div className="cookie-btns">
          <button
            className="btn btn-text"
            onClick={() => {
              setAcceptCookies(false);
              setOpenCookie(false);
              setCookieDenied(true);
            }}
          >
            Decline
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setAcceptCookies(true);
              setOpenCookie(false);
              setCookieDenied(false);
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieModal;
