import { FormEvent, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsTwitterX, BsInstagram } from "react-icons/bs";

// firebase
import { addADocument, emailExist } from "../../db/firebase";

const Subscribe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const emailRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  // console.log("subcribe render");

  // init state
  const initState = () => {
    formRef.current?.reset();
    setIsLoading(false);

    setTimeout(() => {
      setMsg("");
    }, 4000);
  };

  // submit handler
  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    // console.log("submitted", emailRef.current?.value.trim());
    const email = emailRef.current?.value.trim();

    try {
      if (email) {
        const isEmail = await emailExist(email);

        if (isEmail.empty) {
          const res: any = await addADocument("interestedUsers", {
            email,
          });

          // console.log(res);
          if (res.id) {
            initState();

            setMsg("Your email has been successfully submitted ✅ 😊");
          } else {
            initState();

            setMsg("Something went wrong please try again 🙁");
          }
        } else {
          initState();

          setMsg("Email already registered ❌");
        }
      }
    } catch (error) {
      console.log(error);
      initState();

      setMsg("Something went wrong please try again 🙁");
    }
  };

  return (
    <section>
      <div className="container subscribe_section">
        <div className="subscribe_left">
          <h3>Interested?</h3>
          <h2 className="header_title">Join us</h2>
          <p>
            And get the latest updates from <b>Sh!OM.</b>
          </p>

          <div>
            <p
              className="my-1"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              Follow us:
            </p>
            <div className="social_links">
              <a
                href="https://x.com/SHIOMVERSE"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitterX size={24} fill="#fff" />
              </a>

              <a
                href="https://www.instagram.com/shiomverse"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram size={24} fill="#fff" />
              </a>

              {/* <a href="example.com" target="_blank" rel="noreferrer">
                <BsFacebook size={24} fill="#fff" />
              </a>

              <a href="example.com" target="_blank" rel="noreferrer">
                <BsYoutube size={24} fill="#fff" />
              </a> */}
            </div>
          </div>
        </div>

        <form onSubmit={submitHandler} ref={formRef}>
          <div className="subscribe_right">
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
              ref={emailRef}
              onChange={() => setMsg("")}
            />

            <button className="btn btn-primary">
              {isLoading ? <AiOutlineLoading3Quarters /> : "I'm in"}
            </button>
          </div>

          {/* response message */}
          {msg && <p className="mt-1">{msg}</p>}
        </form>
      </div>
    </section>
  );
};

export default Subscribe;
