import Cookies from "js-cookie";

const setCookies = (cookieName: string, cookieValue: string) => {
  Cookies.set(cookieName, cookieValue, {
    expires: 7, // 1 day
    secure: true,
    sameSite: "Strict",
    path: "/",
  });
};

export default setCookies;
