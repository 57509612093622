import { useState, useEffect } from "react";
import "./HomePage.css";
// import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import { BsImages } from "react-icons/bs";
import { BsTwitterX, BsInstagram } from "react-icons/bs";

// components
import EmailModal from "../../components/EmailModal/EmailModal";
import CookieModal from "../../components/CookieModal/CookieModal";
import Subscribe from "../../components/Subscribe/Subscribe";

//svg
import logo_XL from "../../assets/svg/logo/SHIOM_LOGO_type_orange.svg";
import pointSvg from "../../assets/svg/point.svg";
import realificationSvg from "../../assets/svg/realification.svg";
import multiscaleViewSvg from "../../assets/svg/multiscale_view.svg";
import claritySvg from "../../assets/svg/clarity.svg";
import revealificationSvg from "../../assets/svg/revealification.svg";
// vertical svgs
import { clinical, generic } from "../../assets/svg/verticals/index";

// images
import demo1Img from "../../assets/img/demo1.jpeg";
import demo2Img from "../../assets/img/demo2.jpeg";

// import Brown_University_logo from "../../assets/svg/collab-logos/Brown_University.png";
// import IIT_Kharagpur_Logo from "../../assets/svg/collab-logos/IIT_Kharagpur_Logo.png";
import Field_Logo from "../../assets/svg/collab-logos/Field_Logo_White.png";
import fractal_dataminds from "../../assets/svg/collab-logos/fractal_dataminds.webp";
import interchangelab_logo from "../../assets/svg/collab-logos/interchangelab_logo.png";
import Alcor_Scientific_Logo from "../../assets/svg/collab-logos/Alcor-Scientific-Logo-gray.svg";

// hooks
import setCookies from "../../hooks/setCookies";
import getCookies from "../../hooks/getCookies";

// firebase firestore
import { addADocument } from "../../db/firebase";

const videoUrl = process.env.REACT_APP_VIDEO_URL;

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [acceptCookies, setAcceptCookies] = useState(false);
  const [cookieDenied, setCookieDenied] = useState(false);
  const [openCookie, setOpenCookie] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (getCookies("userIn") || getCookies("denyCookie")) {
        setOpenCookie(false);
      } else {
        setOpenCookie(true);
      }
    }, 4000);
  }, []);

  // useEffect hook
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [isOpen]);

  const queryClickHandler = () => {
    setIsOpen(true);
  };

  // check visitor and add cookies hook

  useEffect(() => {
    const checkVisitors = async () => {
      try {
        // success call back
        const successCB = async (position: GeolocationPosition) => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          const response = await fetch(
            `${process.env.REACT_APP_LOCATION_API}/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`
          );
          const data = await response.json();

          if (data) {
            const { city, countryName } = data;
            await addADocument("visitors", { city, countryName });
          }
        };

        // error call back
        const errorCB = (e: GeolocationPositionError) => {
          console.warn(e.message);
        };

        navigator.geolocation.getCurrentPosition(successCB, errorCB);
      } catch (error) {
        console.log(error);
      }
    };

    const cookie = getCookies("userIn");
    const denyCookie = getCookies("denyCookie");

    if (!cookie && acceptCookies) {
      setCookies("userIn", uuidv4());
      checkVisitors();
    }
    if (!denyCookie && cookieDenied) {
      setCookies("denyCookie", uuidv4());
    }
  }, [acceptCookies, cookieDenied]);

  return (
    <>
      {/* header section */}
      <header>
        <div className="container text-center">
          <img src={logo_XL} alt="shiom logo" />
          <h1 className="header_title mt-1">Cleaning The Internet</h1>
          <p className="header_para mt-1">
            clean data is <span className="highlight-text">Good Data</span>
          </p>
        </div>
      </header>

      <main>
        {/* video section */}
        <section className="container">
          <div className="d-flex">
            {/* <div className="video-section"> */}
            <video className="video-section" controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
            {/* </div> */}
          </div>
          <div className="btn_section">
            <a
              className="btn btn-primary"
              href="https://socks-verse.blogspot.com/2020/10/what-and-why-socks.html"
              target="_blank"
              rel="noreferrer"
            >
              Blogs
            </a>
            {/* <Link to={"/investor-relations"} className="btn btn-text">
              Investor Relations
            </Link> */}
            <a
              className="btn btn-text"
              href="https://patentcenter.uspto.gov/applications/17819114"
              target={"_blank"}
              rel="noreferrer"
            >
              Patent Filing
            </a>

            <button className="btn btn-text" onClick={queryClickHandler}>
              Query?
            </button>
          </div>
        </section>

        {/* Partners & Collaborators */}
        <section className="container p-1">
          <h2 className="text-center" style={{ marginTop: "6rem" }}>
            Partners & Collaborators
          </h2>

          <div className="partners_collaborators">
            {/* <a
              href="https://www.brown.edu/"
              target="_blank"
              rel="noreferrer"
              className="d-flex gap-10"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <img src={Brown_University_logo} alt="" width={25} height={50} />
              <div style={{}}>
                <h4>Brown University</h4>
                <p>RI, USA</p>
              </div>
            </a> */}

            {/* <a
              href="https://www.iitkgp.ac.in/"
              target="_blank"
              rel="noreferrer"
              className="d-flex gap-10"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <img src={IIT_Kharagpur_Logo} alt="" width={50} height={50} />
              <div>
                <h4>IIT Kharagpur</h4>
                <p>WB, INDIA</p>
              </div>
            </a> */}

            <a
              href="https://www.experiencethefield.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Field_Logo}
                alt="experiencethefield"
                width={150}
                height={50}
              />
            </a>

            <a
              href="https://fractal-data.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={fractal_dataminds}
                alt="fractal-data"
                width={150}
                height={50}
              />
            </a>

            <a
              href="https://www.interchangelab.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={interchangelab_logo}
                alt="interchangelab"
                width={100}
                height={80}
              />
            </a>

            <a
              href="https://alcorscientific.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Alcor_Scientific_Logo}
                alt="Alcor Scientific"
                width={150}
                height={50}
              />
            </a>
          </div>
        </section>

        {/* modal section */}

        <section>
          {isOpen && <EmailModal setIsOpen={setIsOpen} isOpen={isOpen} />}
        </section>

        {/* why shiom section */}
        <section className="features p-1">
          <div className="container">
            <div className="features_title">
              <img src={pointSvg} alt="point tag" width={50} height={50} />
              <h2>
                Why Sh<span className="color-yellow">!</span>OM
              </h2>
            </div>

            <div className="features_body">
              <div className="features_card">
                <img src={realificationSvg} alt="realification" />
                <h3 className="my-1">Realification</h3>
                <p>Turning data into the most realistic form</p>
              </div>

              <div className="features_card">
                <img src={multiscaleViewSvg} alt="multi-scale view" />
                <h3 className="my-1">Multi-scale View</h3>
                <p>Taste of reality at various scales</p>
              </div>

              <div className="features_card">
                <img src={claritySvg} alt="magnified clarity" />
                <h3 className="my-1">Magnified Clarity</h3>
                <p>Improved perception with enhanced clarity</p>
              </div>

              <div className="features_card">
                <img src={revealificationSvg} alt="revealification" />
                <h3 className="my-1">Revealification</h3>
                <p>Seeing the unseen already available in the data</p>
              </div>
            </div>
          </div>
        </section>

        {/* images demo section */}
        <section className="p-1">
          <div className="container">
            <h2 className="my-1 p-1">Illustrative Demonstrations</h2>

            <div className="demo_img_section">
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/shiom-414ff.appspot.com/o/gallery-images%2Fmontage_A_view_of_the_M87_supermassive_black_hole_in_polarised_light.png?alt=media&token=5af298a8-1e49-4905-9116-c416a8d41714"
                }
                alt="demo 0"
              />

              <div>
                <p>
                  Images (1 - 5) curated by{" "}
                  <b>
                    <i>SH!OM LENS</i>
                  </b>{" "}
                  reveal never-before-seen intricate structural details about{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Messier_87"
                    target="_blank"
                    rel="noreferrer"
                  >
                    the M87* blackhole
                  </a>
                  . (2) reveal mesmerizing aesthetic beauty to demonstrate great
                  artistic richness underlying the cosmic creation carved out
                  from the apparently simple looking structure of the original
                  EHT image
                </p>
              </div>
            </div>

            <div className="demo_img_section">
              <img src={demo1Img} alt="demo 1" />

              <div>
                <p>
                  Restoration of Monalisa painting using <b>Sh!OM technology</b>
                  . The white speckles are curated to closely represent the
                  original features of the painting. Through our{" "}
                  <i>
                    <b>realification technology</b>
                  </i>
                  , we have the opportunity to see the{" "}
                  <i>
                    <b>first-ever</b>
                  </i>
                  ,{" "}
                  <i>
                    <b>distortion-free</b>
                  </i>
                  ,{" "}
                  <i>
                    <b>close to original</b>
                  </i>{" "}
                  Monalisa created by Leonardo da Vinci.
                </p>
              </div>
            </div>

            <div className="demo_img_section">
              <img src={demo2Img} alt="demo 2" />

              <div>
                <p>
                  Using the <b>Sh!OM technology</b> we are enabling curation of
                  baby ultrasound images to reveal multitude of features at
                  scale
                </p>
              </div>
            </div>

            {/* <div className="img_gallery_card">
              <h3>
                For more let's discover our Sh
                <span className="color-yellow">!</span>
                OM's image gallery
              </h3>
              <Link
                to="/gallery"
                className="btn btn-primary d-flex"
                style={{ textDecoration: "none", gap: "5px" }}
              >
                <BsImages /> Sh!OM gallery
              </Link>
            </div> */}
          </div>
        </section>

        {/* youtube podcast ref section */}
        <section className="container p-1">
          <div
            className="d-flex gap-10"
            style={{
              justifyContent: "start",
              maxWidth: "300px",
              marginBottom: "20px",
            }}
          >
            <img src={pointSvg} alt="point tag" width={50} height={50} />

            <h2>
              Check out our latest{" "}
              <span style={{ fontSize: "2rem", textTransform: "uppercase" }}>
                podcast
              </span>
            </h2>
          </div>

          <div className="yt_podcast">
            <div className="yt_podcast_left">
              <iframe
                width="100%"
                height="315px"
                src="https://www.youtube.com/embed/7FDkHz0tcnE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                allowFullScreen
              ></iframe>
              <p className="mt-1">
                In this episode, Dr. Prasanta Pal, founder of shiom.io,
                discusses his journey and the pivotal moments that led to the
                establishment of his company. He explains what exactly clean
                data is, its importance, and how his technology revolutionizes
                traditional AI approaches by enhancing the accuracy and
                reliability of AI models.
              </p>
            </div>

            <div className="yt_podcast_right">
              <iframe
                width="100%"
                height="315px"
                src="https://www.youtube.com/embed/3xPF7cp_h6o?si=jA6TKJtnRyXPBBIV"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              <p className="mt-1">
                Dr. Pal’s interview on our neuro-tech innovation and
                collaboration with FIELD
              </p>
            </div>
          </div>

          {/* <div className="yt_podcast">
            <div className="yt_podcast_left">
              <div className="d-flex gap-10">
                <img src={pointSvg} alt="point tag" width={50} height={50} />

                <h2>
                  Check out our latest{" "}
                  <span
                    style={{ fontSize: "2rem", textTransform: "uppercase" }}
                  >
                    podcast
                  </span>
                </h2>
              </div>
              <p className="mt-1">
                In this episode, Dr. Prasanta Pal, founder of shiom.io,
                discusses his journey and the pivotal moments that led to the
                establishment of his company. He explains what exactly clean
                data is, its importance, and how his technology revolutionizes
                traditional AI approaches by enhancing the accuracy and
                reliability of AI models.
              </p>
            </div>

            <div className="yt_podcast_right">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/7FDkHz0tcnE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                allowFullScreen
              ></iframe>
            </div>
          </div> */}
        </section>

        {/* shiom vertical section */}
        <section className="container vertical_section">
          <h2 className="text-center">
            Sh<span className="color-yellow">!</span>OM Verticals
          </h2>

          {/* clinical section */}
          <div className="card_wrapper">
            {clinical.map((icon, id) => (
              <div
                className="card"
                key={id}
                onClick={() => {
                  if (icon.url === "https://ultrababy.io/") {
                    window.open(icon.url, "_blank");
                  }
                }}
              >
                <img src={icon.svg} alt="clinical icons" />
                <p className="text-center">{icon.name}</p>
              </div>
            ))}
          </div>

          {/* generic section */}
          <div className="card_wrapper">
            {generic.map((icon, id) => (
              <div className="card" key={id}>
                <img src={icon.svg} alt="generic icons" />
                <p className="text-center">{icon.name}</p>
              </div>
            ))}
          </div>
        </section>

        {/* shiom subscribe section OR signup section */}
        <Subscribe />

        {/* social links side nav */}
        <section>
          <div className="side_social_links">
            <a href="https://x.com/SHIOMVERSE" target="_blank" rel="noreferrer">
              <BsTwitterX size={24} fill="#fff" />
            </a>

            <a
              href="https://www.instagram.com/shiomverse"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram size={24} fill="#fff" />
            </a>

            {/* <a href="example.com" target="_blank" rel="noreferrer">
                <BsFacebook size={24} fill="#fff" />
              </a>

              <a href="example.com" target="_blank" rel="noreferrer">
                <BsYoutube size={24} fill="#fff" />
              </a> */}
          </div>
        </section>
      </main>

      {/* cookies policy */}
      {openCookie && (
        <CookieModal
          setAcceptCookies={setAcceptCookies}
          setOpenCookie={setOpenCookie}
          setCookieDenied={setCookieDenied}
        />
      )}
    </>
  );
};

export default HomePage;
